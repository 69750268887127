main {
  min-height: 80vw;
}
h3 {
  padding: 1rem 0;
}
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
.rating span {
  margin: 0.1rem;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}
.carousel-caption h2 {
  color: white;
}
.carousel img {
  height: 400px;
  padding: 20px;
  margin: 50px;
  border-radius: 50%;
}
.carousel a {
  margin: 0 auto;
}
